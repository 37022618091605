import React, { ReactElement, useState } from 'react';
import './Header.scss';
import Logo from '../../assets/images/banana_logo.png';
import Winners from '../winners/Winners';
import Rules from '../Rules/Rules';
import { HashLink } from 'react-router-hash-link';
import { useTranslation } from 'react-i18next';

const Header: React.FC = (): ReactElement => {
  const { t } = useTranslation();

  const [winnersActive, setWinnersActive] = useState<boolean>(false);

  const [rulesActive, setRulesActive] = useState<boolean>(false);

  return (
    <div className="Header">
      <Winners
        winnersModalActive={winnersActive}
        setWinnersModalActive={setWinnersActive}
      />

      <Rules
        rulesModalActive={rulesActive}
        setRulesModalActive={setRulesActive}
      />
      <div className="logo-container">
        <img src={Logo} alt="" />
      </div>
      <div className="banner-container">
        <div className="banner-image">
          <h2>Võida reis Banaanivabariiki!</h2>
        </div>
      </div>
      <div className="columns container">
        <div className="description-container column">
          <div className="campaign-description">
            {t('campaign description')}
          </div>
          <div className="campaign-period">
            <span>{t('campaign period')}</span>
          </div>
        </div>
        <div className="nav-container column">
          <button
            className="button"
            onClick={() => setWinnersActive(!winnersActive)}
          >
            {t('winners')}
          </button>
          <button
            className="button"
            onClick={() => setRulesActive(!rulesActive)}
          >
            {t('rules')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Header;

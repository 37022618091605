import React, { ReactElement } from 'react';
import './Main.scss';

import Prizes from '../../assets/images/banana_prizes.png';

import Register from '../../components/register/Register';
import Header from '../../components/header/Header';
import { useTranslation } from 'react-i18next';

const Main: React.FC = (): ReactElement => {
  const { t } = useTranslation();

  return (
    <div className="Main">
      <section className="header">
        <Header />
      </section>
      <section className="content">
        <div className="columns container">
          <div className="column prizes">
            <img src={Prizes} alt="" />
          </div>
          <div className="column register-form">
            <Register />
          </div>
        </div>
      </section>
      <section className="footer">
        <div className="container">
          <span>{t('copyright')}</span>
        </div>
      </section>
    </div>
  );
};

export default Main;
